var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-custom" },
    [
      _vm._l(_vm.dropdownMenuItems, function (item, i) {
        return [
          item.show
            ? _c(
                "div",
                {
                  key: i,
                  class:
                    _vm.activeTab === i
                      ? "nav-link nav-link-active"
                      : "nav-link",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("selected", i)
                    },
                  },
                },
                [
                  item.iconName
                    ? _c("CIcon", { attrs: { name: item.iconName } })
                    : _vm._e(),
                  _c("div", { staticClass: "nav-item-text" }, [
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }