var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _vm.menuItems[0]
        ? _c("LinkGroupSolid", {
            attrs: {
              activeTab: _vm.selectedTab,
              dropdownMenuItems: _vm.menuItems,
            },
            on: {
              selected: function ($event) {
                _vm.selectedTab = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "CCard",
        {
          staticClass:
            "shadow-none referral-info-card mb-0 align-items-center h-100",
          attrs: { color: "second-card-bg" },
        },
        [
          _c(
            "CCardBody",
            { staticClass: "referral-info-card__body align-items-center" },
            [
              _c(
                "h2",
                { staticClass: "referral-info-card__title" },
                [_vm._t("title")],
                2
              ),
              _c(
                "div",
                { staticClass: "referral-info-card__count" },
                [_vm._t("value"), _vm._t("post-value")],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }