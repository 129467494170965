<template>
  <div class="d-flex flex-column">
    <LinkGroupSolid
              v-if="menuItems[0]"
              @selected="selectedTab = $event"
              :activeTab="selectedTab"
              :dropdownMenuItems="menuItems" />
    <CCard class="shadow-none referral-info-card mb-0 align-items-center h-100" color="second-card-bg">
      <CCardBody class="referral-info-card__body align-items-center">
        <h2 class="referral-info-card__title">
          <slot name="title" />
        </h2>
        <div class="referral-info-card__count">
          <slot name="value" />
          <slot name="post-value" />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import LinkGroupSolid from "@/pages/lk/LinkGroupSolid";

export default {
  name: 'ReferralInfoCard',
  components: {
    LinkGroupSolid
  },
  props: {
    menuItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedTab: 0
    }
  },
  watch: {
    selectedTab(val) {
      this.$emit('selectedTab', val)
    }
  }
};
</script>

<style lang="scss">
.referral-info-card {
  overflow: hidden;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    text-align: center;
    font-size: 15px;
    line-height: 1.4;
    color: var(--white);
    // margin-bottom: 4rem;
    margin-bottom: 1.9rem

    // @include media-breakpoint-down(md){
    //   margin-bottom: 1rem;
    // }
  }

  &__count {
    font-size: 50px;
    line-height: 1;
    color: var(--white);
    white-space: nowrap;

    span {
      font-size: 25px;
    }

    // @include media-breakpoint-down(md){
    //   align-self: end;
    // }
  }
}

.diff {
  font-size: 0.7em;
}
</style>
